var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:".container"},[_c('Header'),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"banner"},[_c('div',[_c('p',[_vm._v("development")])]),_c('h1',[_vm._v(" PC Virgo ")]),_c('p',[_vm._v(" Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"parallax"},[_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',[_c('p',[_vm._v("El reto")]),_c('h2',[_vm._v(" Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery ")]),_c('p',[_vm._v(" PC Virgo en Hermosillo, Sonora. Su éxito lo llevó a recibir pedidos a nivel nacional, por lo que desarrollamos una tienda en línea con un servidor que cumpla con la alta demanda de compras que recibe diariamente. ")])]),_c('div',[_c('picture',[_c('source',{attrs:{"srcset":require("../../assets/desarrollo/virgo/content_img.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/desarrollo/virgo/content_img.png"),"alt":"Imagen decorativa","loading":"lazy"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"video"},[_c('iframe',{staticClass:"only-pc",attrs:{"src":"https://www.youtube.com/embed/va9KJthqxaU?si=RGoik-5m5TCKOlVp&autoplay=1&mute=1&loop=1&playlist=va9KJthqxaU&showinfo=0&controls=0","title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","referrerpolicy":"strict-origin-when-cross-origin","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"imagen__mov"},[_c('picture',[_c('source',{attrs:{"srcset":require("../../assets/desarrollo/virgo/imagen_mov.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/desarrollo/virgo/imagen_mov.png"),"alt":"Imagen de prueba virgo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pallete"},[_c('h2',[_vm._v("Color Palette")]),_c('div',[_c('p',[_vm._v("Lust | principal")]),_c('h3',[_vm._v("#E21F1D")])]),_c('div',[_c('p',[_vm._v("Raising Black | secundario")]),_c('h3',[_vm._v("#212121")])]),_c('div',[_c('p',[_vm._v("Ripe Mango | resalte")]),_c('h3',[_vm._v("#FEC128")])]),_c('div',[_c('p',[_vm._v("Quick Silver | texto")]),_c('h3',[_vm._v("#A7A7A7 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mosaico__video"},[_c('picture',[_c('source',{attrs:{"srcset":require("../../assets/desarrollo/virgo/imagen_video.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/desarrollo/virgo/imagen_video.png"),"alt":"Imagen de virgo","loading":"lazy"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mosaico"},[_c('picture',[_c('source',{attrs:{"srcset":require("../../assets/desarrollo/virgo/mosaico_1.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/desarrollo/virgo/mosaico_1.png"),"alt":"Prueba de virgo","loading":"lazy"}})]),_c('div',{staticClass:"mosaico__stack"},[_c('picture',[_c('source',{attrs:{"srcset":require("../../assets/desarrollo/virgo/mosaico_2.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/desarrollo/virgo/mosaico_2.png"),"alt":"Prueba de virgo","loading":"lazy"}})]),_c('picture',[_c('source',{attrs:{"srcset":require("../../assets/desarrollo/virgo/mosaico_3.webp"),"type":"image/webp"}}),_c('img',{attrs:{"src":require("../../assets/desarrollo/virgo/mosaico_3.png"),"alt":"Prueba de virgo","loading":"lazy"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"last__content"},[_c('h2',[_vm._v("Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery ")]),_c('p',[_vm._v(" ¿Saben lo que se me hizo más difícil? Quedarme encerrada en casa, y creo que no se van a oponer, pero para el primer mes. ")])])
}]

export { render, staticRenderFns }